import React from 'react';

const buildDate = () => {
  const versionBuildDate = new Date();
  return (
    `${versionBuildDate.getMonth()}/${versionBuildDate.getDay()}/${versionBuildDate.getFullYear()}`
  );
}

export const BuildDate = () => {
  return (
    <div>
      <p>Version: 0.0.5</p>
      <p>Last Updated: { `${ buildDate() } `}</p>
    </div>
  )
}