import React from "react";
import ThreeColumnGrid from "./ThreeColumnWithMain";
import { Hamburger } from "../Icons";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {showNav} from "../state"

export default () => {

  const [open, setOpen] = useRecoilState(showNav);
  return (
    <>
      <ThreeColumnGrid>
        <div onClick = { () => setOpen(!open) } style={{display: "grid", placeItems: "center"}}>
          <Hamburger
            style={{
              padding: '1px',
              border: '1px solid black',
              borderRadius: '13%',
              boxShadow: '2px 2px 3px grey',
              width: '50%',
              height: '20px',
              margin: '10px',
              transition: 'transform 0.3s',
              cursor: 'pointer'
            }} 
          />
        </div> 
        <div>   
          <h1 style={{
                fontFamily: 'DatalegreyaThin',
                fontWeight: 'normal',
                fontStyle: 'normal',
                textAlign: "center"
              }}
          >BTC Chokidar</h1>
        </div>
        <div></div>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <div></div>
        <div>
          <p style={{textAlign:"center", fontSize: "0.9em"}}>
            “It is well enough that people of the nation do not understand our banking and monetary system, for if they did, I believe there would be a revolution before tomorrow morning.”
          <br/>
          - Henry Ford          
          </p>
        </div>
        <div></div>
      </ThreeColumnGrid>
    </>

  )
}